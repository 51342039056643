/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    p: "p",
    ol: "ol",
    li: "li",
    a: "a",
    code: "code",
    h3: "h3",
    table: "table",
    thead: "thead",
    tr: "tr",
    th: "th",
    tbody: "tbody",
    td: "td",
    strong: "strong"
  }, _provideComponents(), props.components), {Boon, Condition, Effect, Skill, Warning, Specialization, BuildLink, Instability, Item, Trait, Attribute, Augmentation} = _components;
  if (!Attribute) _missingMdxReference("Attribute", true);
  if (!Augmentation) _missingMdxReference("Augmentation", true);
  if (!Boon) _missingMdxReference("Boon", true);
  if (!BuildLink) _missingMdxReference("BuildLink", true);
  if (!Condition) _missingMdxReference("Condition", true);
  if (!Effect) _missingMdxReference("Effect", true);
  if (!Instability) _missingMdxReference("Instability", true);
  if (!Item) _missingMdxReference("Item", true);
  if (!Skill) _missingMdxReference("Skill", true);
  if (!Specialization) _missingMdxReference("Specialization", true);
  if (!Trait) _missingMdxReference("Trait", true);
  if (!Warning) _missingMdxReference("Warning", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.h2, null, "What is necessary to clear fractals efficiently?"), "\n", React.createElement(_components.p, null, "It boils down to five essential concepts a group must fulfill permanently in combat. Failing one of these will decelerate the speed and smoothness of the run:"), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "Boons: 25 stacks of ", React.createElement(Boon, {
    name: "Might"
  }), ", ", React.createElement(Boon, {
    name: "Fury"
  }), ", ", React.createElement(Boon, {
    name: "Quickness"
  }), " and ", React.createElement(Boon, {
    name: "Alacrity"
  }), "."), "\n", React.createElement(_components.li, null, "Conditions: 25 stacks of ", React.createElement(Condition, {
    name: "Vulnerability"
  }), " and any unique conditions your class needs to gain damage modifiers."), "\n", React.createElement(_components.li, null, "Breaking ", React.createElement(_components.a, {
    href: "guides/cc-distribution"
  }, "CC bars"), " to gain the damage modifier from ", React.createElement(Effect, {
    name: "Exposed"
  }), "."), "\n", React.createElement(_components.li, null, "High and fast group single target burst potential."), "\n", React.createElement(_components.li, null, "Using strong damaging class skills such as ", React.createElement(Skill, {
    name: "Spider Venom",
    id: "13037"
  }), " or ", React.createElement(Skill, {
    name: "onewolfpack",
    id: "45717"
  })), "\n"), "\n", React.createElement(Warning, null, React.createElement(_components.p, null, "It is vital to understand that these concepts, in conjunction, create massive bursts, causing phases to only last a few seconds - even with condition classes. The ", React.createElement(Effect, {
    name: "Exposed"
  }), " modifier also stacks multiplicatively with many other damage modifiers, reinforcing this effect."), React.createElement(_components.p, null, "Therefore, the gameplay is much more fast-paced than raids and requires different thinking and rotations tailored around the ", React.createElement(Effect, {
    name: "Exposed"
  }), " window.")), "\n", React.createElement(_components.p, null, "For T4s, there are several other important concepts:"), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "Projectile reflects and ", React.createElement(Boon, {
    name: "Stability"
  }), "."), "\n", React.createElement(_components.li, null, "High cleave damage."), "\n", React.createElement(_components.li, null, "Fast movement using leaps, blinks, and teleports to perform skips for the team."), "\n", React.createElement(_components.li, null, React.createElement(Effect, {
    name: "Stealth"
  }), " to skip trash mobs."), "\n"), "\n", React.createElement(_components.h2, null, "What and how do I play in PuGs?"), "\n", React.createElement(_components.p, null, "PuGs rarely play optimally as a group, causing people to generally deal lower damage and perform worse overall due to a lack of synergy. If you are one of those who seek to improve, we highly encourage you to find a static via our ", React.createElement(_components.code, null, "#looking-for-static"), " channel on ", React.createElement(_components.a, {
    href: "https://discord.gg/NpS2gN5"
  }, "Discord"), " or ", React.createElement(_components.a, {
    href: "apply"
  }, "apply to Discretize"), ". We encourage the fractal community to strive for self-improvement and help in any possible way on our Discord server or in-game."), "\n", React.createElement(_components.p, null, "For people without the time to do this, we recommend quite an open comp when using the in-game LFG. While every class in the game has multiple strong builds for fractals, building your party around certain boons is still vital to help have a somewhat efficient clear."), "\n", React.createElement(_components.p, null, "By choosing builds that complement each other to bring the necessary boons, conditions, CC, and support, a run will typically be a smoother, more enjoyable experience than one without these things. You first goal is to cover the important boons: ", React.createElement(Boon, {
    name: "Might",
    text: "25 Might"
  }), ", ", React.createElement(Boon, {
    name: "Fury"
  }), ", ", React.createElement(Boon, {
    name: "Quickness"
  }), " and ", React.createElement(Boon, {
    name: "Alacrity"
  }), ". Additionally, you as a group should aim to maintain ", React.createElement(Condition, {
    name: "vulnerability",
    text: "25 Vulnerability"
  }), " on your targets. Finally, builds that can bring high CC and damage mitigation, such as reflects, ", React.createElement(Boon, {
    name: "Aegis"
  }), " and ", React.createElement(Boon, {
    name: "Stability"
  }), " are much more beneficial to run than pure damage dealers."), "\n", React.createElement(Warning, null, React.createElement(_components.p, null, "The compositions below are robust options with support builds that synergize well and are capable of handling all fractal mechanics, leading to quick, consistent, and smooth runs at all gameplay levels. Every class in the game has at least one option for a boon heal, boon DPS, power DPS, and condition DPS build sufficient to play in fractals, which can be an alternate option to the builds below."), React.createElement(_components.p, null, "We want to note however, that boon builds such as ", React.createElement(Specialization, {
    name: "Mechanist"
  }), ", ", React.createElement(Specialization, {
    name: "Druid"
  }), ", or ", React.createElement(Specialization, {
    name: "Tempest"
  }), " while providing all of the necessary boon support and healing, can sometimes have less access to damage mitigation or only one skill that provides ", React.createElement(Boon, {
    name: "Stability"
  }), " and ", React.createElement(Boon, {
    name: "Aegis"
  }), ". The reduced damage mitigation is no issue if other people in your party recognize it and adapt their play-style or builds to help. However, if their attitude is for the healer to carry the group, these builds can be much more complicated to play effectively.")), "\n", React.createElement(_components.h3, null, "Example Power Composition"), "\n", React.createElement(_components.p, null, "Typically, we play power compositions on most normal mode fractals, Nightmare CM and Shattered Observatory CM."), "\n", React.createElement(_components.table, null, React.createElement(_components.thead, null, React.createElement(_components.tr, null, React.createElement(_components.th), React.createElement(_components.th))), React.createElement(_components.tbody, null, React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(Specialization, {
    name: "Chronomancer",
    size: "big",
    disable: true,
    text: true
  })), React.createElement(_components.td, null, React.createElement(BuildLink, {
    build: "Heal Chronomancer",
    specialization: "Chronomancer"
  }), " is one of the strongest, if not the strongest heal build currently for fractals. It can provide either ", React.createElement(Boon, {
    name: "Alacrity"
  }), " or ", React.createElement(Boon, {
    name: "Quickness"
  }), ", as well as 25 stacks of ", React.createElement(Boon, {
    name: "Might"
  }), " and ", React.createElement(Boon, {
    name: "Fury"
  }), ". It has multiple options for on-demand ", React.createElement(Boon, {
    name: "Stability"
  }), " and ", React.createElement(Boon, {
    name: "Aegis"
  }), ", as well as other mitigation such as reflects, plenty of condi cleanse, and Crowd Control. Finally, it has a unique toolkit for T4s with access to blinks and movement abilities for skips along with ", React.createElement(Effect, {
    name: "Stealth"
  }), " and some ", React.createElement(Effect, {
    name: "Superspeed"
  }), ". This composition uses it as an ", React.createElement(Boon, {
    name: "Alacrity"
  }), " provider. ", React.createElement("br"), " ", React.createElement(_components.strong, null, "Alternative Options"), " - If the heal spot serves as a ", React.createElement(Boon, {
    name: "Quickness"
  }), " provider, or you are in a no heal group. We recommend playing ", React.createElement(BuildLink, {
    build: "Power Renegade",
    specialization: "Renegade"
  }), " or ", React.createElement(Specialization, {
    name: "Willbender",
    text: "Power Alacrity Willbender"
  }), " in this slot.")), React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(Specialization, {
    name: "Scrapper",
    size: "big",
    disable: true,
    text: true
  })), React.createElement(_components.td, null, React.createElement(BuildLink, {
    build: "Power Scrapper",
    specialization: "Scrapper"
  }), " is a strong ", React.createElement(Boon, {
    name: "Quickness"
  }), " DPS option. It provides large amounts of Crowd Control, ", React.createElement(Effect, {
    name: "Stealth"
  }), ", and ", React.createElement(Effect, {
    name: "Superspeed"
  }), ", making T4 fractals easy. It also has High target and cleave damage, along with Boon stip on ", React.createElement(Skill, {
    name: "Throw Mine",
    id: "6161"
  }), " effectively dealing with ", React.createElement(Instability, {
    name: "No Pain, No Gain"
  }), ". Through ", React.createElement(Skill, {
    name: "Blast Gyro",
    id: "31248"
  }), ", it is also capable of helping maintain party ", React.createElement(Boon, {
    name: "Might"
  }), ". ", React.createElement("br"), " ", React.createElement(_components.strong, null, "Alternative Options"), " - For a more defensive option ", React.createElement(BuildLink, {
    build: "Power Herald",
    specialization: "Herald"
  }), " can be taken. Or, if you have a ", React.createElement(Boon, {
    name: "Alacrity"
  }), " DPS provider, ", React.createElement(BuildLink, {
    build: "Heal Chronomancer",
    specialization: "Chronomancer"
  }), " or ", React.createElement(BuildLink, {
    build: "Heal Firebrand",
    specialization: "Firebrand"
  }), " can be played as a ", React.createElement(Boon, {
    name: "Quickness"
  }), " heal option.")), React.createElement(_components.tr, null, React.createElement(_components.td, null, "Power DPS"), React.createElement(_components.td, null, "The final three spots are for pure DPS builds. In PuGs, whether a build is META or not doesn't matter. All that matters is if the build is good, which every class in the game can make. A good build has an equipment combination, traits, and skills that make sense for the encounter it is being played on. Try to pick a build that complements the rest of the party and be willing to compromise if the boon supports need extra help with situational boons (such as ", React.createElement(Boon, {
    name: "Stability"
  }), " or ", React.createElement(Boon, {
    name: "Aegis"
  }), "), condi cleanse, or providing some boon strips if needed.")))), "\n", React.createElement(_components.h3, null, "Example Condition Composition"), "\n", React.createElement(_components.p, null, "Condition compositions are typically taken on Sunqua Peak CM and Silent Surf CM and can be beneficial to run on some T4s."), "\n", React.createElement(_components.table, null, React.createElement(_components.thead, null, React.createElement(_components.tr, null, React.createElement(_components.th), React.createElement(_components.th))), React.createElement(_components.tbody, null, React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(Specialization, {
    name: "Specter",
    size: "big",
    disable: true,
    text: true
  })), React.createElement(_components.td, null, React.createElement(BuildLink, {
    build: "Condi Specter",
    specialization: "Specter"
  }), " is a strong condition ", React.createElement(Boon, {
    name: "Alacrity"
  }), " DPS option. It offers competitive damage, along with plenty of CC through ", React.createElement(Skill, {
    name: "Basilisk Venom",
    id: "13132"
  }), " and ", React.createElement(Skill, {
    name: "Headshot",
    id: "13012"
  }), " on ", React.createElement(Item, {
    id: "72239",
    text: "Off-hand Pistol",
    id: "72239"
  }), ". Additionally, ", React.createElement(Trait, {
    name: "Bountiful Theft",
    id: "1277"
  }), " can be taken to effectively deal with ", React.createElement(Instability, {
    name: "No Pain, No Gain"
  }), ", as well as offering some group healing and support through ", React.createElement(Trait, {
    name: "Consume Shadows",
    id: "2275"
  }), " and ally targeting with ", React.createElement(Item, {
    id: "76688",
    text: "Scepter",
    id: "76688"
  }), " skills. ", React.createElement("br"), " ", React.createElement(_components.strong, null, "Alternative Options"), " - Alternatively ", React.createElement(BuildLink, {
    build: "Condi Alac Renegade",
    specialization: "Renegade"
  }), " or ", React.createElement(Specialization, {
    name: "Scourge",
    text: "Condition Alacrity Scourge"
  }), " can be played in this slot. Or if you feel like your group doesn't need a full healer, ", React.createElement(BuildLink, {
    build: "Celestial Scourge",
    specialization: "Scourge"
  }), " is a solid hybrid build that is great for more confident groups. If you are playing with a Quickness DPS build, an Alacrity heal build such as ", React.createElement(BuildLink, {
    build: "Heal Chronomancer",
    specialization: "Chronomancer"
  }), " can be played instead.")), React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(Specialization, {
    name: "Chronomancer",
    size: "big",
    disable: true,
    text: true
  })), React.createElement(_components.td, null, React.createElement(BuildLink, {
    build: "Heal Chronomancer",
    specialization: "Chronomancer"
  }), " is one of the strongest, if not the strongest heal build currently for fractals. It can provide either ", React.createElement(Boon, {
    name: "Alacrity"
  }), " or ", React.createElement(Boon, {
    name: "Quickness"
  }), ", as well as 25 stacks of ", React.createElement(Boon, {
    name: "Might"
  }), " and ", React.createElement(Boon, {
    name: "Fury"
  }), ". It has multiple options for on-demand ", React.createElement(Boon, {
    name: "Stability"
  }), " and ", React.createElement(Boon, {
    name: "Aegis"
  }), ", as well as other mitigation such as reflects, plenty of condi cleanse, and Crowd Control. Finally, it has a unique toolkit for T4s with access to blinks and movement abilities for skips along with ", React.createElement(Effect, {
    name: "Stealth"
  }), " and some ", React.createElement(Effect, {
    name: "Superspeed"
  }), ". This composition uses it as an ", React.createElement(Boon, {
    name: "Alacrity"
  }), " provider. ", React.createElement("br"), " ", React.createElement(_components.strong, null, "Alternative Options"), " - If you are playing with an Alacrity Heal provider ", React.createElement(Specialization, {
    name: "Herald",
    text: "Condition Quickness Herald"
  }), " can be taken, which shares its boons in a massive 600 range and is great on Silent Surf, however it has less access to CC. For a more offensive option, ", React.createElement(BuildLink, {
    build: "Condition Harbinger",
    specialization: "Harbinger"
  }), " is a great choice. ", React.createElement(BuildLink, {
    build: "Condi Firebrand",
    specialization: "Firebrand"
  }), " is an option with powerful utility. Still, care has to be taken when sharing quickness to ensure high uptime.", React.createElement(BuildLink, {
    build: "Heal Firebrand",
    specialization: "Firebrand"
  }), " can also be played as a ", React.createElement(Boon, {
    name: "Quickness"
  }), " heal option.")), React.createElement(_components.tr, null, React.createElement(_components.td, null, "Condition DPS"), React.createElement(_components.td, null, "The final three spots are for pure DPS builds. In PuGs, whether a build is META or not doesn't matter. All that matters is if the build is good, which every class in the game can make. A good build has an equipment combination, traits, and skills that make sense for the encounter it is being played on. Try to pick a build that complements the rest of the party and be willing to compromise if the boon supports need extra help with situational boons (such as ", React.createElement(Boon, {
    name: "Stability"
  }), " or ", React.createElement(Boon, {
    name: "Aegis"
  }), "), condi cleanse, or providing some boon strips if needed.")))), "\n", React.createElement(_components.h3, null, "Example Lonely Tower CM Composition"), "\n", React.createElement(_components.p, null, "Due to its encounter design, being drastically different from previous fractals, Lonely Tower has a much higher success rate by adjusting your composition to manage the mechanics of the Eparch encounter."), "\n", React.createElement(_components.table, null, React.createElement(_components.thead, null, React.createElement(_components.tr, null, React.createElement(_components.th), React.createElement(_components.th))), React.createElement(_components.tbody, null, React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(Specialization, {
    name: "Scourge",
    size: "big",
    disable: true,
    text: true
  })), React.createElement(_components.td, null, React.createElement(Specialization, {
    name: "Scourge",
    text: "Heal Alacrity Scourge"
  }), " is a strong ", React.createElement(Boon, {
    name: "Alacrity"
  }), " support option for Eparch CM. It can maintain ", React.createElement(Boon, {
    name: "Alacrity"
  }), ", 25 stacks of ", React.createElement(Boon, {
    name: "Might"
  }), ", ", React.createElement(Boon, {
    name: "Fury"
  }), ", ", React.createElement(Boon, {
    name: "Protection"
  }), ", and some ", React.createElement(Boon, {
    name: "Regeneration"
  }), ", while providing ", React.createElement(Effect, {
    name: "Barrier"
  }), " to help the party survive Eparchs mechanics. It also has access to plenty of CC to help deal with Eparchs 3000 defiance bar. Additionally, it helps to control adds in the split phases with ", React.createElement(Condition, {
    name: "Fear"
  }), ", ", React.createElement(Skill, {
    name: "Spectral Ring",
    id: "10608"
  }), ", and Greatsword to minimise the amount of emotion stacks Eparch gains, aswell as being able to boonstrip on days with ", React.createElement(Instability, {
    name: "No Pain, No Gain"
  }), ". ", React.createElement("br"), " ", React.createElement(_components.strong, null, "Alternative Options"), " - Alternatively ", React.createElement(BuildLink, {
    build: "Heal Chronomancer",
    specialization: "Chronomancer"
  }), " can be played in this slot. Or if you feel like your group doesn't need a full healer, ", React.createElement(BuildLink, {
    build: "Celestial Scourge",
    specialization: "Scourge"
  }), " is a solid hybrid build that is great for more confident groups.")), React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(Specialization, {
    name: "Herald",
    size: "big",
    disable: true,
    text: true
  })), React.createElement(_components.td, null, React.createElement(BuildLink, {
    build: "Power Herald",
    specialization: "Herald"
  }), " is a good pick for Eparch CM, due to its massive 600 range boon application. It shares ", React.createElement(Boon, {
    name: "Quickness"
  }), ", ", React.createElement(Boon, {
    name: "Might"
  }), ", ", React.createElement(Boon, {
    name: "Fury"
  }), ", ", React.createElement(Boon, {
    name: "Protection"
  }), ", and ", React.createElement(Boon, {
    name: "Swiftness"
  }), ", while also extending all boons on allies through ", React.createElement(Skill, {
    id: "29371",
    id: "29371"
  }), " increasing the duration of boons applied by 20% (up to 120% ", React.createElement(Attribute, {
    name: "Boon Duration"
  }), ") and ", React.createElement(Skill, {
    id: "51696",
    id: "51696"
  }), ". If required, it can also bring Staff, for a large CC skill with ", React.createElement(Skill, {
    name: "Surge of the Mists",
    id: "28978"
  }), ". ", React.createElement("br"), " ", React.createElement(_components.strong, null, "Alternative Options"), " - Alternatively ", React.createElement(BuildLink, {
    build: "Power Scrapper",
    specialization: "Scrapper"
  }), " is an equally viable option in this slot, while it has a smaller boon applictation range, it offers a large amount of CC and ", React.createElement(Effect, {
    name: "Superspeed"
  }), " which massively helps the party with picking up Globules.")), React.createElement(_components.tr, null, React.createElement(_components.td, null, "Gluttony DPS"), React.createElement(_components.td, null, "This is a special role that picks up the Globules of Gluttony in the Eparch CM fight. With Gluttony stacks, your attacks also lifesteal, which can be further increased with ", React.createElement(Item, {
    id: "102245",
    id: "102245"
  }), ". This makes builds such as ", React.createElement(BuildLink, {
    build: "Condi Willbender",
    specialization: "Willbender"
  }), " and ", React.createElement(BuildLink, {
    build: "Power Catalyst",
    specialization: "Catalyst"
  }), " great picks due to lots of fast or multi hitting attacks proccing the lifesteal. ", React.createElement("br"), " ", React.createElement(_components.strong, null, "Alternative Options"), " - Alternatively builds like ", React.createElement(BuildLink, {
    build: "Power Scrapper",
    specialization: "Scrapper"
  }), " or ", React.createElement(Specialization, {
    name: "Willbender",
    text: "Power Willbender"
  }), " preform well in this slot. When considering a build to play, remember the lifesteal from Gluttony has no internal cooldown, so the more you can hit an enemy the better.")), React.createElement(_components.tr, null, React.createElement(_components.td, null, "Rage DPS"), React.createElement(_components.td, null, "Depending on the strategy this role might take up one or two slots in the party. Rage DPS is any power build that picks up the Globules of Rage in the Eparch encounter, which increases their outgoing strike damage. When considering a build for this slot, builds with cleave damage can be useful to help in the split phases with managing adds, and also builds that have access to CC to help break Eparchs bar are preferable. Common choices include ", React.createElement(BuildLink, {
    build: "Power Scrapper",
    specialization: "Scrapper"
  }), " and ", React.createElement(BuildLink, {
    build: "Power Dragonhunter",
    specialization: "Dragonhunter"
  }), ".")))), "\n", React.createElement(_components.h2, null, "What are the differences between the organized groups and how PuGs play?"), "\n", React.createElement(_components.p, null, "One of the most significant differences is that they don't always understand how their actions translate into the game. In the beginning, learning to do the mechanics correctly, flawlessly, and reliably is the most critical task. PuGs usually assume the worst, so one player in the party is a healer, serving as a backup."), "\n", React.createElement(_components.p, null, "Another difference is that PuGs often claim that CC is solely the job of the supports. That is false since they can only knock out ", React.createElement(_components.strong, null, "about half"), " of the bars. CC is a group effort involving ", React.createElement(_components.strong, null, "all"), " party members, ", React.createElement(_components.strong, null, "EVEN IN CONDITION COMPS!")), "\n", React.createElement(_components.p, null, "Many PuGs like to play ", React.createElement(Specialization, {
    name: "Virtuoso"
  }), " or ", React.createElement(Specialization, {
    name: "Scourge"
  }), ". That is fine, as you don't know what to expect of your fellow PuG mates and prefer to be self-sufficient. But please don't be afraid to run more supportive skills such as ", React.createElement(Skill, {
    name: "Signet of Humility",
    id: "29519"
  }), " or ", React.createElement(Skill, {
    name: "Spectral Grasp",
    id: "10620"
  }), "! It is worth more than the +500 DPS you gain from having a minor DPS utility instead."), "\n", React.createElement(_components.h3, null, "Adjusting your gear for the encounter"), "\n", React.createElement(_components.p, null, "When playing in PuG groups, you will often find a considerable disparity in damage dealt by players (even when playing the same build). While this doesn't consider differences in rotation, or account augmentations such as ", React.createElement(Augmentation, {
    name: "Mist Attunement 4"
  }), ", adjusting your build to the encounter can significantly impact the damage you are dealing. While you are not forced to do this, if you want to optimize your gameplay, it is an excellent place to start."), "\n", React.createElement(_components.p, null, "If you take ", React.createElement(BuildLink, {
    build: "Power Soulbeast",
    specialization: "Soulbeast"
  }), ", the standard setup runs with ", React.createElement(Item, {
    id: "24615",
    id: "24615"
  }), ", ", React.createElement(Item, {
    id: "24868",
    id: "24868"
  }), ", ", React.createElement(Item, {
    id: "91805",
    id: "91805"
  }), ", and ", React.createElement(Item, {
    id: "9443",
    id: "9443"
  }), ". However, some encounters allow you to use slaying sigils and potions, or you can proc on-kill food. We can see how much the DPS increases by adjusting the build using the ", React.createElement(_components.a, {
    href: "https://optimizer.discretize.eu/?s=wmPELQkev1"
  }, "gear optimizer with these settings"), "."), "\n", React.createElement(_components.table, null, React.createElement(_components.thead, null, React.createElement(_components.tr, null, React.createElement(_components.th, null, "Standard Item(s)"), React.createElement(_components.th, null, "Optimized Item(s)"), React.createElement(_components.th, null, "Damage Increase"))), React.createElement(_components.tbody, null, React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(Item, {
    id: "91805",
    id: "91805"
  })), React.createElement(_components.td, null, React.createElement(Item, {
    id: "43360",
    id: "43360"
  }), " (100% Uptime)"), React.createElement(_components.td, null, "2%")), React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(Item, {
    id: "9443",
    id: "9443"
  })), React.createElement(_components.td, null, React.createElement(Item, {
    id: "50082",
    id: "50082"
  })), React.createElement(_components.td, null, "6.2%")), React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(Item, {
    id: "91805",
    id: "91805"
  }), " + ", React.createElement("br"), " ", React.createElement(Item, {
    id: "9443",
    id: "9443"
  })), React.createElement(_components.td, null, React.createElement(Item, {
    id: "43360",
    id: "43360"
  }), " (100% Uptime) + ", React.createElement("br"), " ", React.createElement(Item, {
    id: "50082",
    id: "50082"
  })), React.createElement(_components.td, null, "8.5%")), React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(Item, {
    id: "24868",
    id: "24868"
  }), " (Only 3%)"), React.createElement(_components.td, null, React.createElement(Item, {
    id: "24658",
    id: "24658"
  })), React.createElement(_components.td, null, "6.8%")), React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(Item, {
    id: "91805",
    id: "91805"
  }), " + ", React.createElement("br"), " ", React.createElement(Item, {
    id: "9443",
    id: "9443"
  }), " + ", React.createElement("br"), " ", React.createElement(Item, {
    id: "24868",
    id: "24868"
  }), " (Only 3%)"), React.createElement(_components.td, null, React.createElement(Item, {
    id: "43360",
    id: "43360"
  }), " (100% Uptime) + ", React.createElement("br"), " ", React.createElement(Item, {
    id: "50082",
    id: "50082"
  }), " + ", React.createElement("br"), " ", React.createElement(Item, {
    id: "24658",
    id: "24658"
  })), React.createElement(_components.td, null, "15.9%")))), "\n", React.createElement(_components.h3, null, "Prestacking Boons"), "\n", React.createElement(_components.p, null, "Another thing that PuGs sometimes miss is the benefits of a proper prestack. We are not talking about spending ages placing traps in the arena or on the boss spawn or spending time precasting extra weapon skills, but simply before starting an encounter (or mini-boss / mob group in T4s) spending a couple of seconds before starting a fight making sure you have 25 stacks of ", React.createElement(Boon, {
    name: "Might"
  }), ", ", React.createElement(Boon, {
    name: "Fury"
  }), ", ", React.createElement(Boon, {
    name: "Quickness"
  }), " and ", React.createElement(Boon, {
    name: "Alacrity"
  }), ". When starting the fight, you will instantly be doing as much damage as possible, casting skills quicker, and getting cool-downs back sooner. It also gives you a significant reserve of boons in case something goes wrong in the fight later, and you miss some applications, allowing you to recover more quickly."), "\n", React.createElement(_components.p, null, "A proper prestack on 100% boon duration will give your party 25 stacks of ", React.createElement(Boon, {
    name: "Might"
  }), " for 40s, and 30s of ", React.createElement(Boon, {
    name: "Fury"
  }), ", ", React.createElement(Boon, {
    name: "Quickness"
  }), ", and ", React.createElement(Boon, {
    name: "Alacrity"
  }), ". This can be achieved in around 5 seconds or sometimes less. To do this, you need one player to place a fire field and have everyone use at least one blast finisher. Each blast finisher provides three stacks of ", React.createElement(Boon, {
    name: "Might"
  }), " with a base duration of 20s. At the same time, Your support players should share ", React.createElement(Boon, {
    name: "Quickness"
  }), " and ", React.createElement(Boon, {
    name: "Alacrity"
  }), ", and then you can start the fight. Check each ", React.createElement(_components.a, {
    href: "/builds"
  }, "build page"), " for build-specific prestack skills."), "\n", React.createElement(_components.h2, null, "What are strong comps for organized groups?"), "\n", React.createElement(Warning, null, React.createElement(_components.p, null, "It is important to note that each challenge mote encounter has a different effective composition, often distinct from the proposed compositions below. Discretize focuses on clearing daily fractals efficiently, and the proposed compositions bring enough utility to deal with all situations occurring in fractals without needing to re-log to another character for every boss or fractal. Check out the ", React.createElement(_components.a, {
    href: "/guides/daily-comps"
  }, "dT Daily Comps"), " to see these examples.")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
